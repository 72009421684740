// NAV
// ***

.navbar-dark {
  background-color: $brand-app;
}

.navbar {
  top: 0px;
  padding: 0px 2rem;
  transition: padding-top 0.3s, margin-top 0.3s;

  .container {
    transition: margin-top 0.3s;
    margin-top: 33px;
    padding-bottom: 30px;
    max-width: 415px;
  }
}

.navbar-logo-outer {
  width: 150px
}

.navbar-logo {
  transition: width 0.3s, margin 0.3s;
  max-height: 70px;
  width: 75%;
  height: auto;
}

// this is the mobile hide mobile animation end result
.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;

  .container {
    margin-top: 0;
  }
}


// this is a bootstrap class override
.navbar-nav {
  display: block;
  padding-left: 70px;
}


@include media-breakpoint-up(sm) {

  .navbar {
    .container {
      padding-bottom: 0px;
    }
  }

  .navbar-nav {
    display: flex;
    margin-bottom: 0px;
  }

  // this is the desktop shrink animation end result
  .navbar.navbar-animate {
    padding-top: 0px;
    margin-top: 0;

    .navbar-logo {
      width: 70%;
      margin: 5px 0;
    }

    .container {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {

  .navbar {
    .container {
      max-width: 563px;
    }
  }

  .navbar-logo {
    width: 100%;
  }

  .navbar-btn-descr {
    font-size: 17px;
    margin-right: 5px;
  }

  .nav-item {
    a {
      font-size: 17px;
    }
  }
}


@include media-breakpoint-up(lg) {
  .navbar {
    .container {
      max-width: 960px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    .container {
      max-width: 1140px;
    }
  }
}