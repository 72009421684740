.jumbotron {
    padding: 120px 0px;
    background-color: $brand-app;

    .container {
        overflow: visible;
        padding: 0 2rem;
        max-width: 480px;
    }

    h1 {
        font-family: $font-family-goth;
        font-weight: 700;
        font-size: 35px;
    }

    p {
        font-size: 18px;
        margin: 35px 0;

        a {
            font-weight: 700;
        }
    }

    h1, p {
        color: #fff;
    }

    .jumbotron-regions-grid {
        width: 120px;
        margin: 35px auto 0 auto;
        padding: 40px 0 0 0;
        display: grid;
        grid-gap: 20px;
        //grid-template-columns: repeat(auto-fill, 120px);

        a {
            width: 100%;
            background-color: #F9B834;
            border-radius: 5px;
            height: 48px;
            line-height: 48px;
            margin: 0 auto;
            text-align: center;
            color: #fff;
            font-weight: 700;
            font-size: 30px;
            box-shadow: 0px 4px 7px 0px rgba(55,106,132,0.76);
            
            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
                outline: none;
                box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.551);
            }
        }
    }

    .jumbotron-regions {
        background-image: url("/images/hero_img.svg");
        background-size: cover;
        background-position: center;
        height: 361px;
        max-width: 400px;
        margin: 0 auto;
    }
}


@include media-breakpoint-up(md) {
    .jumbotron {

        .container {
            max-width: 626px;
        }

        h1 {
            font-size: 40px;
        }

        p {
            font-size: 22px;
        }

    }
}


@include media-breakpoint-up(lg) {
    .jumbotron {
        padding: 170px 0px 40px 0;

        .container {
            max-width: 960px;
            padding: 0;
        }

        .jumbotron-content {
            width: 526px;
        }

        h1 {
            font-size: 60px;
        }

        p {
            font-size: 25px;
        }

        .jumbotron-regions {
            width: 100%;
            background-position: top;
        }
    }
}

@include media-breakpoint-up(xl) {
    .jumbotron {
        .container {
            max-width: 1140px;
        }

        h1 {
            font-size: 60px;
        }

        .jumbotron-content {
            width: 600px;
        }

        .jumbotron-regions-grid {
            width: 120px;
            grid-gap: 40px 55px;
        }

        .jumbotron-regions {
            height: 500px;
            max-width: 550px;
        }
    }
}



